import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faXTwitter,
  faInstagram,
  faFacebook,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { Box, Typography, IconButton } from "@mui/material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#000", padding: "2rem", textAlign: "center" }}
    >
      <Box sx={{ marginBottom: "1rem" }}>
        <IconButton
          component="a"
          href="https://www.youtube.com/@Yemidyn_"
          target="_blank"
          sx={{ color: "#FFF" }}
        >
          <FontAwesomeIcon icon={faYoutube} />
        </IconButton>
        <IconButton
          component="a"
          href="https://x.com/YEMIDYN?t=Vzy13U40u7bJEl-eR9XKOg&s=09"
          target="_blank"
          sx={{ color: "#FFF" }}
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.tiktok.com/@yemidyn?_t=8ohlm71LSy5&_r=1"
          target="_blank"
          sx={{ color: "#FFF" }}
        >
          <FontAwesomeIcon icon={faTiktok} />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.instagram.com/yemidyn?igsh=a3h4eTZoeDR2NGtp"
          target="_blank"
          sx={{ color: "#FFF" }}
        >
          <FontAwesomeIcon icon={faInstagram} />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.facebook.com/profile.php?id=61557370460882"
          target="_blank"
          sx={{ color: "#FFF" }}
        >
          <FontAwesomeIcon icon={faFacebook} />
        </IconButton>
      </Box>
      <Typography variant="body2" sx={{ color: "#FFF" }}>
        © 2025 Yemidyn. Developed in Mukadaz Labs.
      </Typography>
    </Box>
  );
};

export default Footer;
