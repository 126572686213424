import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import hero1 from "../assets/han.jpeg";
import hero2 from "../assets/h.jpeg";
import hero3 from "../assets/hero22.jpg";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const glowText = keyframes`
  0%, 100% {
    text-shadow: 0 0 30px rgba(255,255,255,0.3);
  }
  50% {
    text-shadow: 0 0 50px rgba(255,255,255,0.5);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const HeroSectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#000",
  position: "relative",
  overflow: "hidden",
  textAlign: "center",
  padding: theme.spacing(2),
}));

const HeroImage = styled(Box)(({ image }) => ({
  width: "100%",
  height: "100%",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "absolute",
  top: 0,
  left: 0,
  transition: "opacity 2s ease-in-out, transform 6s ease-in-out",
  opacity: 0,
  transform: "scale(1.1)",
  "&.active": {
    opacity: 1,
    transform: "scale(1)",
  },
}));

const HeroOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  background: "linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.8) 100%)",
  zIndex: 1,
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.3) 100%)",
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  color: "#fff",
  textAlign: "center",
  maxWidth: "800px",
  padding: theme.spacing(2),
  animation: `${fadeIn} 1.5s ease-out`,
}));

const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: "4.5rem",
  fontWeight: "800",
  marginBottom: theme.spacing(2),
  letterSpacing: "2px",
  animation: `${glowText} 3s infinite ease-in-out`,
  background: "linear-gradient(45deg, #fff 30%, #f3f3f3 90%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textTransform: "uppercase",
  fontFamily: "'Playfair Display', serif",
  [theme.breakpoints.down("md")]: {
    fontSize: "3.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  marginBottom: theme.spacing(4),
  fontWeight: 300,
  lineHeight: 1.6,
  letterSpacing: "1px",
  opacity: 0,
  animation: `${slideIn} 1s ease-out forwards`,
  animationDelay: "0.5s",
  fontFamily: "'Montserrat', sans-serif",
  textShadow: "0 2px 4px rgba(0,0,0,0.3)",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.2rem",
  },
}));

const HeroButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  color: "#000",
  fontWeight: "600",
  padding: theme.spacing(1.8, 4),
  fontSize: "1.1rem",
  textTransform: "none",
  borderRadius: "30px",
  transition: "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
  boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
  opacity: 0,
  animation: `${fadeIn} 1s ease-out forwards`,
  animationDelay: "1s",
  "&:hover": {
    backgroundColor: "#f50057",
    color: "#fff",
    transform: "translateY(-2px)",
    boxShadow: "0 6px 20px rgba(245,0,87,0.4)",
  },
  "&:active": {
    transform: "translateY(1px)",
  },
}));

const HeroSection = () => {
  const [activeImage, setActiveImage] = useState(0);
  const images = [hero1, hero2, hero3];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage((prevImage) => (prevImage + 1) % images.length);
    }, 3500);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section id="home">
      <HeroSectionContainer>
        {images.map((image, index) => (
          <HeroImage
            key={index}
            image={image}
            className={index === activeImage ? "active" : ""}
          />
        ))}
        <HeroOverlay />
        <HeroContent>
          <HeroTitle variant="h1">YemiDyn</HeroTitle>
          <HeroSubtitle>
            Where Creativity Meets Fashion
          </HeroSubtitle>
          <HeroButton href="#shop-now" variant="contained">
            Explore Beyond
          </HeroButton>
        </HeroContent>
      </HeroSectionContainer>
    </section>
  );
};

export default HeroSection;